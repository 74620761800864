export const colors = {
  primary: {
    base: "#302c4f",
    dark: "#1c1a2e",
  },
  secondary: {
    base: "#b0876f",
    dark: "#966b52",
  },
}

export const defaultColors = {
  lightGrey: "#fafafa",
  grey: "#333",
  black: "#000",
  white: "#fff",
}
