export const spacing = [
  // 0
  "5px",
  // 1
  "10px",
  // 2
  "15px",
  // 3
  "30px",
  // 4
  "60px",
  // 5
  "80px",
  // 6
  "160px",
  // 7
  "250px",
  // 8
  "400px",
]
