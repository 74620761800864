export const breakpoints = {
  sm: "300px",
  md: "768px",
  lg: "1024px",
}

export const breakpointsAsInt = {
  sm: parseInt(breakpoints.sm.replace("px", "")),
  md: parseInt(breakpoints.md.replace("px", "")),
  lg: parseInt(breakpoints.lg.replace("px", "")),
}
