import { createGlobalStyle } from "styled-components"
import NormalizeCss from "../../node_modules/normalize.css/normalize.css"
import FontFoundryFormSerif from "../fonts/foundry-form-serif/font.css"
import FontGTHaptik from "../fonts/gt-haptik/font.css"

export const GlobalStyle = createGlobalStyle`
    ${NormalizeCss}

    // Fonts
    ${FontFoundryFormSerif}
    ${FontGTHaptik}

    .font-gt-haptik{
        font-family: "GTHaptikMedium", "Helvetica", "serif";
    }

    .font-foundry-form{
        font-family: "FoundryFormSerifBook", "Times", "serif";
    }

    *, *:before, *:after{
        box-sizing: border-box;
    }
    
    body{
        font-family: "FoundryFormSerifBook", "Times", "serif";
        line-height: 1.5;
    }

    a{
        text-decoration: none;
    }

    img.fluid{
        max-width: 100%;
        height: auto;
    }

    // Headroom
    .headroom {
        will-change: transform;
        transition: transform 200ms linear;
    }

    .headroom--pinned {
        transform: translateY(0%);
    }

    .headroom--unpinned {
        transform: translateY(-100%);
    }
`
