/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// Module imports
const { handleWindowScroll } = require("./src/helper")
const { breakpointsAsInt } = require("./src/designstystem")
const SmoothScroll = require("smooth-scroll")
//const Headroom = require("headroom.js")

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  // Adding "scrolled" class depending on scroll-position
  window.onscroll = handleWindowScroll

  // Adding smooth scroll to anchor links
  new SmoothScroll('a[href*="#"]', {
    offset: () => (window.outerWidth >= breakpointsAsInt.md ? 100 : 50),
  })
}
